<template>
    <div class="locations-modal">
        <div class="locations-list" v-for="(location, n) in getLocations" :key="n">
            <div class="location-content">
                <h4>{{ location.address.name }}</h4>

                
                <div class="location-address">
                    <div class="location-address-distance">
                        <img
                            src="@/assets/svg/v2/settings/billingDelivery.svg"
                            alt="Distance"
                            width="16"
                            height="16"
                        />
                        {{ location.distance }}
                    </div>
                    <div>
                        <p>{{ location.address.address }}, {{ location.address.zip }} {{ location.address.city }} ({{ location.address.country }})</p>
                    </div>
                </div>
    
                <p class="location-hours">{{ location.hours }}</p>
                <p class="location-comments">{{ location.comments }}</p>

                <el-checkbox v-if="!alreadySaveChecked(location)" effect="dark" v-model="location.save" :true-value="1" :false-value="0" :label="$t('payment_process.save_relay')" />
            </div>

            <div class="location-action">
                <button @click="selectLocation(location)" class="generic-btn">{{ $t('commons.select') }}</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'

 export default {
    emits: ['close'],
    data() {
      return {}
    },
    computed: {
        ...mapGetters({
            getLocations: 'getLocations'
        }),
    },
    methods: {
        selectLocation(location) {
            this.$store.commit('SET_LOCATION_SELECTED', location);
            this.$emit('close');
        },
        alreadySaveChecked(location) {
            const findSaveChecked = find(this.getLocations, (l) => {
                return l.save === 1 || l.is_relay_reference;
            })

            if (findSaveChecked) {
                if (findSaveChecked.address.name === location.address.name) {
                    return false;
                }
                return true;
            }
            return false;
        }
    },
    mounted() {}
}
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.locations-modal {
    padding: 0 20px;

    .locations-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #222;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;

        .location-content {

            h4 {
                margin-bottom: 8px;
            }
    
            .location-comments {
                color: $subtitle;
                font-size: 14px;
                font-weight: 300;
            }
        }

        .location-action {

            button {
                width: 120px;
            }
        }
    }
}

.location-address {
    display: flex;
    align-items: end;
    margin-bottom: 8px;

    .location-address-distance {
        display: flex;
        align-items: center;
        background-color: #333;
        border-radius: 8px;
        padding: 2px 5px;
        margin-right: 10px;
    }
}

.el-checkbox__label {
    color: white;
}

@media screen and (max-width: 500px) {

}
</style>
  