<template>
  <div class="back">
    <img src="@/assets/svg/v2/arrowToLeft.svg" alt="Back" />
    <button @click="back()">{{ $t('commons.back') }}</button>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.back {
  margin-bottom: 23px;

  button {
    background: transparent;
    border: 0;
    color: $subtitle;
    font-size: 15px;
    font-weight: 500;
  }
}
</style>
